import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';

import {Observable} from 'rxjs';

import {AuthService} from '../_services/auth.service';
import {apiEnvironment} from '../_libs/api.config';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.token) {
      let token = currentUser.token;
      if (req.url === apiEnvironment.hookUrl) {
        token = apiEnvironment.hookToken;
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
          },
          withCredentials: false
        });
      } else {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
    }
    return next.handle(req);
  }
}
