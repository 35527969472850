import { Injectable } from '@angular/core';
import { AuthGuard } from '../../../../../_guards/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menu: object[] = [];

  constructor(private authGuard: AuthGuard) {

  }

  push(item) {
    this.menu.push(item);
  }

  loadPermission() {
    this.menu = [];
    this.menu.push({type: 'separator', class_icon: 'mdi mdi-dots-horizontal', text: 'Gestión'});
    this.menu.push({
      visible: this.authGuard.verifyPermission('permisoAdmin:true'),
      type: 'parent', class_icon: 'mdi mdi-account-settings-variant', text: 'Supervision',
      children: [
        {
          text: 'Contactos', class_icon: 'mdi mdi-adjust',
          url: '/contacts/admin',
          visible: this.authGuard.verifyPermission('permisoAdmin:true')
        }
      ]
    });
    this.menu.push({
      visible: this.authGuard.verifyPermission('permisoAdmin:true') ,
      type: 'parent', class_icon: 'mdi mdi-engine-outline', text: 'Gestión In',
      children: [
        {
          text: 'Gestión', class_icon: 'mdi mdi-contact-mail',
          url: '/managements/console',
          visible: this.authGuard.verifyPermission('permisoGestion:true')
        }
      ]
    });
  }

  get() {
    this.loadPermission();
    return this.menu;
  }
}
